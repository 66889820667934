import React from 'react';
import Layout from 'layouts';
import {Row, Col} from 'react-bootstrap';

export default class Component extends React.Component {
  state = {
    status: 'ready',
    name: '',
    email: '',
    message: ''
  }

  submit = (e) => {
    e.preventDefault();
    const data = {
      name: this.state.name,
      email: this.state.email,
      message: this.state.message,
    };
    if (this.validate(data)) {
      fetch('https://api.valuestreamventures.com/platform/contact', {
        body: JSON.stringify(data),
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'user-agent': 'Mozilla/4.0 MDN Example',
          'content-type': 'application/json'
        },
        method: 'POST', mode: 'cors', redirect: 'follow', referrer: 'no-referrer',
      }).then(res => {
        this.setState({
          status: 'sent',
          email: '',
          name: '',
          message: ''
        })
      }).catch((err) => {this.setState({status: 'error'})});
    }
  }

  validate = (data) => {
    if (!(data.name && data.name.length > 2)) return false;
    if (!(data.email && data.email.length > 3 && data.email.includes('@'))) return false;
    if (!(data.message && data.message.length > 5)) return false;
    return true
  }

  render() {
    return (
      <Layout {...this.props} >
      <Row noGutters style={{padding: '30px 0px 45px 0px', backgroundColor: '#fff'}}>
        <Col md={{span: 8, offset: 2}} style={{padding: 0}}>
        <div className="wpcf7 wpcf7-form">
            {this.state.status == 'sent' ? <h4 style={{color: 'green'}}>Thanks for reaching out! We will get back as soon as we can.</h4> : null}
            {this.state.status == 'error' ? <h4 style={{color: 'red'}}>Sorry, there was an error sending your email, want to try again?</h4> : null}
            {this.state.status != 'sent' ? <form className="progression-contact" id="CommentForm" onSubmit={this.submit}>
                <fieldset>
                    <p>
                        Your Name <span className="required">*</span> <br />
                        <input value={this.state.name} onChange={e => this.setState({name: e.target.value})} className="textInput required" />
                    </p>
                    <p>
                        Your Email <span className="required">*</span> <br />
                        <input value={this.state.email} onChange={e => this.setState({email: e.target.value})} className="textInput required email" />
                    </p>
                    <p>
                        Your Message <br />
                      <textarea value={this.state.message} onChange={e => this.setState({message: e.target.value})} className="textInput required" rows="8" cols="4" ></textarea>
                    </p>
                    <p>
                        <button type="submit" name="submit" value="submit" className="progression-contact-submit wpcf7-submit" style={{backgroundColor: '#5ac0a0'}}><span>SEND MESSAGE</span></button>
                    </p>
                </fieldset>
            </form> : null}
          </div>
        </Col>
      </Row>
      </Layout>
    )
  }
}
